App.Modules = App.Modules || {};
App.Modules.navMenu = function () {

  var menuAnimating = false;
  var toggleMenu = function(data)
  {
    $('.mobile-menu').toggleClass('open');
    $('body').toggleClass('no-scroll');
  };

  var toggleChildMenu = function(data)
  {
    var $parentEl =  $(data.eventElement.parentElement),
      $childList = $parentEl.find('.child-items'),
      $childItems = $childList.find('li');

    if( $childItems.length ) {
      if (!$parentEl.hasClass('open')) {
        $parentEl.toggleClass('open');

        //caluclate max height of child menu
        $childList[0].style.maxHeight = $childItems[0].offsetHeight * $childItems.length + 'px';
      } else {
        $parentEl.toggleClass('open');
        $childList[0].style.maxHeight = 0;
      }
    }

  };

  var shrinkMenu = //App.Helpers.debounce(
    function(data)
    {
      if( ! $('body').hasClass('no-scroll') ) {
        if (parseInt(data.event.deltaY, 10) !== 0) {
          if (data.event.deltaY > 0) {
            if (!menuAnimating) {
              menuAnimating = true;
              $('.action-links').slideUp(400, function () {
                menuAnimating = false;
              });
              $('.desktop-header .logo').addClass('no-tagline');
            }
          } else {
            if (!menuAnimating) {
              menuAnimating = true;
              $('.action-links').slideDown(400, function () {
                menuAnimating = false;
              });
              $('.desktop-header .logo').removeClass('no-tagline');
            }
          }
        }
      }
    };//, 50);

  var adjustHeight = App.Helpers.debounce(
    function() {
      if( $(window).width() > 1023 ) {
        var menuHeight = $('.desktop-header').outerHeight(true) + $('nav.secondary').outerHeight(true);
        $('.content').css('marginTop', menuHeight + 'px');
      } else {
        if ($('.supplier-campaign__header').length === 0)
        {
          $('.content').css('marginTop', '70px');
        }
      }
    }, 100);

  return {
    init: function() {
      adjustHeight();
      return this;
    },
    events: function() {
      Events.bind('click', '.hamburger').to(toggleMenu, this);
      Events.bind('click', '.close-btn .fa-close').to(toggleMenu, this);
      Events.bind('click', '.js-open-menu').to(toggleChildMenu, this);
      Events.bind('resize').to(adjustHeight, this);
      Events.bind(
        "onwheel" in document.createElement("div") ? "wheel" : // Modern browsers support "wheel"
        document.onmousewheel !== undefined ? "mousewheel" : // Webkit and IE support at least "mousewheel"
          "DOMMouseScroll"
      ).to(shrinkMenu, this, {}, false);
      return this;
    }
  };
}();

