App.Modules = App.Modules || {};
App.Modules.SupplierCampaignMap = function () {

    var isSupplierCampaign = $('.supplier-campaign__content').length,
        hasMap = $('.map-row').length,
        $mapList = $('.map-list'),
        $map = $('.map-display');

  var resizeMap = function(data)
  {
    if (isSupplierCampaign && hasMap)
    {
        if ($mapList.css('float') == 'left')
        {
            var h = $mapList.innerHeight() + 'px';
            $map.css('height', h)
                .css('max-height', h);
        }
    }
  };

  return {
    init: function() {
      return this;
    },
    events: function() {
      $('window').on('resize', resizeMap);
      resizeMap();
      return this;
    }
  };
}();

