App.Modules = App.Modules || {};
App.Modules.TwoChoiceSelector = function () {

  var switchContent = function(data)
  {
    var $this = $(data.eventElement),
      $container = $this.closest('.two-choice-selector'),
      value = $this.attr('data-tab-index');

    $container.find('.two-choice-title-box').removeClass('active');
    $this.addClass('active');
    $container.find('.two-choice-content').removeClass('active');
    $container.find('[data-selector-key="' + value + '"]').addClass('active');
  };

  return {
    init: function() {
      return this;
    },
    events: function() {
      Events.bind('click', '.two-choice-title-box').to(switchContent, this);
      return this;
    }
  };
}();

