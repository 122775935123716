//App.Modules = App.Modules || {};
//App.Modules.FormElements = function () {
//
//  var initForm = function(data)
//  {
//      if (jQuery().chosen)
//      {
//          $(".js-chosen-select").chosen({
//              disable_search_threshold: 10,
//              allow_single_deselect: true
//          });
//
//          if (Modernizr.touch)
//          {
//              $('label.multi-select').show();
//          }
//      }
//  };
//
//  return {
//    init: function() {
//      initForm();
//      return this;
//    },
//    events: function() {
//      return this;
//    }
//  };
//}();

