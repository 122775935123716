App.Modules = App.Modules || {};
App.Modules.RevealFooter = function () {

  var $copyrightFooter = $('.copyright-footer');

  var adjustHeight = App.Helpers.debounce(
    function() {
      var footerHeight = $copyrightFooter.outerHeight(true);
     $('.wrapper').css('marginBottom', footerHeight);
    }, 100);

  var adjustZIndex = App.Helpers.debounce(
    function() {
      if( $('.home').length )
      {
        $vid = $('video');

        if( $(document).scrollTop() > ( $(window).height() + 250 ) )
        {
          //$copyrightFooter.css('zIndex', '-1');

          //pause video to possibly resolve gpu flickering issues
          if($vid.length ) {
           $vid[0].pause();
          }

        } else {
          //$copyrightFooter.css('zIndex', '-2');
          if($vid.length ) {
           $vid[0].play();
          }

          //resume video
        }
      }
  }, 50);

  return {
    init: function() {
      //adjustHeight();
      return this;
    },
    events: function() {
      Events.bind('load').when('div.wrapper:not(.home)').to(adjustHeight, this);
      Events.bind('resize').when('div.wrapper:not(.home)').to(adjustHeight, this);
      Events.bind('scroll').to(adjustZIndex, this);
      return this;
    }
  };
}();

