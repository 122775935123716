App.Modules = App.Modules || {};
App.Modules.HomepageVideo = function () {

  var addVideo = function()
  {
    if( $(window).width() >= 1024 && $('.wrapper.home').length ) {
      var $video = $("<video preload='auto' muted loop autoplay poster='/assets/images/minified/homepage-video-poster.png' style='position: absolute; left: 100%;'><source type='video/mp4' src='/assets/videos/homepage-bg-video.mp4?breakcache' /></video>");

      $('.homepage-bg-video').append($video);

      $video.on('canplaythrough', function() {
        $('.homepage-bg-video').find('video').attr('style', '');
      });
    }
  };

  return {
    init: function() {
      addVideo();
      return this;
    },
    events: function() {
      return this;
    }
  };
}();

