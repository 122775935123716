App.Modules = App.Modules || {};
App.Modules.FullHeight = function () {

  var addFullHeight = function()
  {
    $('[js-add-full-height]').addClass('full-height');
  };

  var removeFullHeight = function()
  {
    $('[js-add-full-height]').removeClass('full-height');
  };

  var adjustHeight = App.Helpers.debounce(
    function()
    {
      if( $(window).width() >= 1260 )
      {
        addFullHeight();
      } else {
        removeFullHeight();
      }
    }, 150);

  return {
    init: function() {
      adjustHeight();
      return this;
    },
    events: function() {
      Events.bind('resize').to(adjustHeight, this);
      return this;
    }
  };
}();

