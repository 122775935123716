App.Modules = App.Modules || {};
App.Modules.CompanyListing = function () {

    var switchState = function(data)
    {
        var stateVal = $(data.eventElement).val(),
            baseURL = $(data.eventElement).data('url'),
            redirect = baseURL;

        if( stateVal )
        {
           redirect += '?state=' + stateVal;
        }

        window.location.href = redirect;
    };

    var switchLetter = function(data)
    {
        var letterVal = $(data.eventElement).val(),
            baseURL = $(data.eventElement).data('url'),
            redirect = baseURL;

        if( letterVal )
        {
            redirect += '/' + letterVal;
        }

        window.location.href = redirect;
    };

    var switchCategory = function(data)
    {
        var categoryVal = $(data.eventElement).val(),
            baseURL = $(data.eventElement).data('url'),
            redirect = baseURL;

        if( categoryVal )
        {
            categoryVal = encodeURIComponent(categoryVal);
            redirect += 'category=' + categoryVal;
        }

        window.location.href = redirect;
    };

  return {
    init: function() {
      return this;
    },
    events: function() {
      Events.bind('change', '.js-state-switch').to(switchState, this);
      Events.bind('change', '.js-letter-switch').to(switchLetter, this);
      Events.bind('change', '.js-category-switch').to(switchCategory, this);
      return this;
    }
  };
}();

