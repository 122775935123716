App.Modules = App.Modules || {};
App.Modules.HeroSlideshow = function () {

  var createSlider = function(selector)
  {
    if( $(selector).length ) {

      $(selector).each( function() {
        var $this = $(this);
        $this.bxSlider({
          controls: true,
          pager: true,
          minSlides: 1,
          maxSlides: 1,
          moveSlides: 1,
          shrinkItems: true,
          touchEnabled: false,
          oneToOneTouch: false,
          preventDefaultSwipeY: false,
          nextSelector: $this.parent().find('.slide-pager .right'),
          nextText: '&#xf054;',
          prevSelector: $this.parent().find('.slide-pager .left'),
          prevText: '&#xf053;',
          mode: 'fade'
        });
      });

    }
  };

  return {

    init: function() {
      createSlider('.hero-slideshow-holder .bxslider');
      return this;
    },
    events: function() {
      return this;
    }
  };
}();

