App.Modules = App.Modules || {};
App.Modules.FooTable = function () {

  return {
    init: function() {
      if($.fn.footable) {
        $('.table').footable();
      }
        return this;
    },
    events: function() {
        return this;
    }
  };
}();

