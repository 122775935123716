App.Modules = App.Modules || {};
App.Modules.NewsFilters = function () {

  var toggleMenu = function(data)
  {
    var $parent = $(data.eventTarget);

    if( $parent.hasClass('js-menu-parent') ) {
      $parent.toggleClass('open');
      $parent.find('ul').slideToggle();
    } else {
      $parent.toggleClass('selected');
      Events.publish("ad/filterNews", {});
    }

  };

  var $industry_inputs = $('li[data-news_filter-industry-id]'),
    $category_inputs = $('li[data-news_filter-category-id]'),
    $loadMore = $('.js-load-news'),
    current_industry_id = [],
    current_category_id = [],
    current_page = 1,
    total_pages = $loadMore.attr('data-num-pages');

  var filterNews = function()
  {
    var industry_id = [],
      category_id = [];

    $industry_inputs.filter('.selected').each(function() {
      industry_id.push( $(this).attr('data-news_filter-industry-id') );
    });

    $category_inputs.filter('.selected').each(function() {
      category_id.push( $(this).attr('data-news_filter-category-id') );
    });

    current_industry_id = industry_id;
    current_category_id = category_id;
    current_page = 1;

    $.get('/news/json', {industry_id: industry_id, category_id: category_id}, function(data){ loadArticles(data, true) }, 'json' );
  };

  var loadMoreNews = function(el)
  {
    if( ! $(el.eventTarget).attr('disabled') ) {
      $.get('/news/json/' + ( ++current_page ), {
        industry_id: current_industry_id,
        category_id: current_category_id
      }, function (data) {
        loadArticles(data)
      }, 'json');
    }
  };

  var loadArticles = function(data, newFilters)
  {
    if( newFilters ) {
      total_pages = data.total_pages;
    }

    if( total_pages == 0 || current_page >= total_pages )
    {
      $loadMore.attr('disabled', 'disabled');
    } else {
      $loadMore.removeAttr('disabled');
    }

    var $itemContainer = $('.news-articles');

    if(newFilters)
    {
      $itemContainer.empty();
    }

    $itemContainer.append(Handlebars.templates.news_articles({items: data.articles, curUrl: window.location.href, imgSrc: '/content/news/images'}));
  };

  return {
    init: function() {
      return this;
    },
    events: function() {
      Events.bind('click', '.news-filters .js-menu-parent').to(toggleMenu, this);
      Events.bind('click', '.js-load-news').to(loadMoreNews, this);
      Events.subscribe("ad/filterNews", filterNews, this);
      return this;
    }
  };
}();

