App.Modules = App.Modules || {};
App.Modules.TabSelector = function () {

  var slider,
      $filters = $('.tab-selector-filter');

  var switchContent = function(data)
  {
    var $this = $(data.eventElement),
      $sliderBox = $this.closest('.js-slider-box'),
      value = $this.val();

    $sliderBox.find('.tab-selector-content').removeClass('active');
    $sliderBox.find('[data-selector-key="' + value + '"]').addClass('active');

    if( value !== '' )
    {
      $this.removeClass('green-white');
      $this.parent().removeClass('green-white');
      $filters.prop( "disabled", false);
    } else {
      $this.addClass('green-white');
      $this.parent().addClass('green-white');
      $filters.prop( "disabled", true);
    }

    filterContent({eventElement: $filters});
  };

  var filterContent = function(data)
  {
    var filterVal = $(data.eventElement).val(),
      $items = $('.tab-selector-content.active').find('.js-filter-item');
    if( ! filterVal )
    {
      $items.show();
    } else {
      $items.hide();
      $items.filter('[data-filter-key="' + filterVal + '"]').show();
    }

  };

  return {
    init: function() {
      $filters.prop( "disabled", true);
      return this;
    },
    events: function() {
      Events.bind('change', '.js-tab-options').to(switchContent, this);
      Events.bind('change', '.tab-selector-filter').to(filterContent, this);
      return this;
    }
  };
}();

