App.Modules = App.Modules || {};
App.Modules.DropdownLinks = function () {

  var switchUrl = function(data)
  {
    var $select = $(data.eventElement),
      newURL = $select.val();

    window.location = newURL;
  };

  return {
    init: function() {
      return this;
    },
    events: function() {
      Events.bind('change', '[data-dropdown-links]').to(switchUrl, this);
      return this;
    }
  };
}();

