App.Modules = App.Modules || {};
App.Modules.SupplierCampaignContacts = function () {

  var toggleDetails = function(data)
  {
    var $select = $(this);
    $select
      .addClass('list__supplier-campaign__selected')
      .closest('.map-list__supplier-campaign').addClass('list__supplier-campaign__details')
      .removeClass('js-supplier-campaign-list-view');
    unbindClicks();
  },
  
  backButton = function (data)
  {
    var $select = $(data.eventElement);
    $select
      .closest('.map-list__supplier-campaign').removeClass('list__supplier-campaign__details')
      .find('.list__supplier-campaign__selected').removeClass('list__supplier-campaign__selected');
    $('.map-list').addClass('js-supplier-campaign-list-view');
    bindClicks();
  },
  
  bindClicks = function () {
    $('.map-list__supplier-campaign .js-map-row').on('click', toggleDetails);
  },
  
  unbindClicks = function () {
    $('.map-list__supplier-campaign .js-map-row').off('click', toggleDetails);
  };

  return {
    init: function() {
      return this;
    },
    events: function() {
      //Events.bind('click', '.map-list__supplier-campaign .js-map-row')
      //  .to(toggleDetails, this);
      Events.bind('click', '.js-supplier-campaign-back-button').to(backButton, this);
      bindClicks();
      return this;
    }
  };
}();

