App.Modules = App.Modules || {};
App.Modules.Search = function () {

  var show_all = function()
  {
    $('.toggle .search-hide').addClass('active');
    $('.toggle .search-show').removeClass('active');
    $('.collapsable').show();
  },

  hide_all = function()
  {
    $('.toggle .search-show').addClass('active');
    $('.toggle .search-hide').removeClass('active');
    $('.collapsable').hide();
  },

  handleClick = function(data) {
      var $this = $(data.eventElement),
        toggle = $this.find('.toggle'),
        products = $this.parents('.cat-holder').find('.collapsable');

      if ($(products).toggle().css('display') == 'none') {
        $(toggle).find('.search-show').addClass('active');
        $(toggle).find('.search-hide').removeClass('active');
      }
      else {
        $(toggle).find('.search-hide').addClass('active');
        $(toggle).find('.search-show').removeClass('active');
      }
      return false;
  };

  // show first set of results
  //$('.cat-top h3:first').click();

  return {
    init: function() {
      hide_all();

      //Fixes an issue where for a single collapsing category is toggled closed on page load
      if($('.collapsable').length == 1)
      {
        if($('.collapsable').css('display') == 'none')
        {
          $('.collapsable').show();
          $('.toggle .search-hide').addClass('active');
          $('.toggle .search-show').removeClass('active');
        }
      }
      return this;
    },
    events: function() {
      Events.bind('click', '.cat-top h3').to(handleClick, this);
      return this;
    }
  };
}();
