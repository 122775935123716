this["Handlebars"] = this["Handlebars"] || {};
this["Handlebars"]["templates"] = this["Handlebars"]["templates"] || {};
this["Handlebars"]["templates"]["introduction"] = Handlebars.template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "<div class=\"entry\">\n   <h2>"
    + alias4(((helper = (helper = helpers.title || (depth0 != null ? depth0.title : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"title","hash":{},"data":data}) : helper)))
    + "</h2>\n   <div class=\"body\">\n      "
    + alias4(((helper = (helper = helpers.body || (depth0 != null ? depth0.body : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"body","hash":{},"data":data}) : helper)))
    + "\n   </div>\n</div>\n";
},"useData":true});
this["Handlebars"]["templates"]["join_ad_canada_notice"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    return "\n      <span style=\"color: #8B1820;\"><strong>Pour être considéré comme un fournisseur canadien de l'électricité, vous devez remplir les conditions suivantes:</strong></span>\n\n      <ol>\n        <li>Vendre actuellement à un minimum de 5 membres AD Canada</li>\n        <li>$75,000 dans les ventes actuelles aux membres AD Canada</li>\n        <li>Générer un niveau de remboursement minimum de $5000</li>\n      </ol>\n\n      <p>\n        <span class=\"btn close\">OK</span>\n      </p>\n\n";
},"3":function(container,depth0,helpers,partials,data) {
    return "\n      <span style=\"color: #8B1820;\"><strong>Para ser considerado como un Proveedor de Electricidad de Canadá debe cumplir con los siguientes requisitos:</strong></span>\n\n      <ol>\n        <li>Actualmente vender a un mínimo de 5 miembros con EA Canadá</li>\n        <li>$75,000 en ventas actuales a los miembros de AD Canadá</li>\n        <li>Generar un nivel de reembolso mínimo de $5,000</li>\n      </ol>\n\n      <p>\n        <span class=\"btn close\">OK</span>\n      </p>\n\n";
},"5":function(container,depth0,helpers,partials,data) {
    return "\n      <span style=\"color: #8B1820;\"><strong>To be considered as a Canadian Electrical Supplier you must meet\n        the following requirements:</strong></span>\n\n      <ol>\n        <li>Currently sell to a minimum of 5 AD Canada members</li>\n        <li>$75,000 in current sales to AD Canada members</li>\n        <li>Generate a minimum rebate level of $5,000</li>\n      </ol>\n\n      <p>\n        <span class=\"btn close\">OK</span>\n      </p>\n\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "<section class=\"team-member-bio\">\n  <div class=\"bio-close close text-white\">\n    <span class=\"fa fa-close close\"></span>\n  </div>\n\n  <div class=\"bio-wrapper\">\n    <div class=\"bio-content\">\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.french : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.spanish : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.english : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n    </div>\n  </div>\n</section>\n";
},"useData":true});
this["Handlebars"]["templates"]["news_articles"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression, alias5=container.lambda;

  return "  <article class=\"news-item\">\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.image : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "    <div class=\"news-date\">\n      "
    + alias4(((helper = (helper = helpers.date || (depth0 != null ? depth0.date : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"date","hash":{},"data":data}) : helper)))
    + "\n      <br />\n      <a href=\""
    + alias4(alias5((depths[1] != null ? depths[1].curUrl : depths[1]), depth0))
    + "/?category_id="
    + alias4(((helper = (helper = helpers.category_id || (depth0 != null ? depth0.category_id : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"category_id","hash":{},"data":data}) : helper)))
    + "\" style=\"text-decoration: underline;\">"
    + alias4(alias5(((stack1 = ((stack1 = (depth0 != null ? depth0.topic : depth0)) != null ? stack1["0"] : stack1)) != null ? stack1.name : stack1), depth0))
    + "</a>\n    </div>\n    <a href=\""
    + alias4(alias5((depths[1] != null ? depths[1].curUrl : depths[1]), depth0))
    + "/"
    + alias4(((helper = (helper = helpers.url_segment || (depth0 != null ? depth0.url_segment : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"url_segment","hash":{},"data":data}) : helper)))
    + "\" class=\"news-title\">"
    + alias4(((helper = (helper = helpers.title || (depth0 != null ? depth0.title : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"title","hash":{},"data":data}) : helper)))
    + "</a>\n  </article>\n";
},"2":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var helper, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), alias4=helpers.helperMissing, alias5="function";

  return "      <a href=\""
    + alias2(alias1((depths[1] != null ? depths[1].curUrl : depths[1]), depth0))
    + "/"
    + alias2(((helper = (helper = helpers.url_segment || (depth0 != null ? depth0.url_segment : depth0)) != null ? helper : alias4),(typeof helper === alias5 ? helper.call(alias3,{"name":"url_segment","hash":{},"data":data}) : helper)))
    + "\">\n        <img src=\""
    + alias2(alias1((depths[1] != null ? depths[1].imgSrc : depths[1]), depth0))
    + "/"
    + alias2(((helper = (helper = helpers.image || (depth0 != null ? depth0.image : depth0)) != null ? helper : alias4),(typeof helper === alias5 ? helper.call(alias3,{"name":"image","hash":{},"data":data}) : helper)))
    + "\" alt=\""
    + alias2(((helper = (helper = helpers.image_alt || (depth0 != null ? depth0.image_alt : depth0)) != null ? helper : alias4),(typeof helper === alias5 ? helper.call(alias3,{"name":"image_alt","hash":{},"data":data}) : helper)))
    + "\" />\n      </a>\n";
},"4":function(container,depth0,helpers,partials,data) {
    return "  <i>No results.</i>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.items : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "<article class=\"news-item--extra\"></article>\n<article class=\"news-item--extra\"></article>\n<article class=\"news-item--extra\"></article>\n"
    + ((stack1 = helpers.unless.call(alias1,(depth0 != null ? depth0.items : depth0),{"name":"unless","hash":{},"fn":container.program(4, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"useData":true,"useDepths":true});