App.Modules = App.Modules || {};
App.Modules.HomepageChartAnim = function () {

  var o = {
    hasRun: false
  };

  var check = function() {
    if(
      $('.homepage-graph-anim').length &&
      $(window).width() >= 680 &&
      ! o.hasRun &&
      $(document).scrollTop() >= ( $('.stats').offset().top - $('header').outerHeight(true) - 200 )
    )
    {
      Events.publish("ad/chartAnim", {});
      o.hasRun = true;
    }
  };

  var animateGreen = function() {
    $('.homepage-graph-anim .bar--green').each(function( index ) {
      $(this).delay( index * 250).velocity({
          height: ( 35 + index * 20 ) + '%'
        },
        1000
      )
    });
  };

  var animateGray= function() {
    $('.homepage-graph-anim .bar--gray').each(function( index ) {
      $(this).delay( index * 250).velocity({
          height: ( 25 + index * 20 ) + '%'
        },
        1000
      )
    });
  };

  return {
    init: function() {
      return this;
    },
    events: function() {
      Events.bind('scroll').to(check, this);
      Events.subscribe("ad/chartAnim", animateGreen, this);
      Events.subscribe("ad/chartAnim", animateGray, this);
      return this;
    }
  };
}();

