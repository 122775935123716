App.Modules = App.Modules || {};
App.Modules.StickyInternationalNav = function () {

  var $srcMenu = $('.industry-link-container').parent();
  var $clonedMenu;

  var handleScroll = App.Helpers.debounce(
    function()
    {
      var stickyPoint = $srcMenu.offset().top - $('header').height();
      if( $(window).scrollTop() >= stickyPoint )
      {
        $clonedMenu.show();
      } else {
        $clonedMenu.hide();
      }
    }, 10);

  return {
    init: function() {
      if( $('.industry-link-container').length ) {
        $clonedMenu = $srcMenu.clone(true).appendTo('header').css({'margin': '0 auto', 'maxWidth': '1920px'});
        $srcMenu.css('visibility', 'hidden');
        $clonedMenu.on('change', function() {
          window.location = $(this).find('select').val();
        })
      }
      return this;
    },
    events: function() {
      if( $('.industry-link-container').length ) {
        //Events.bind('scroll').to(handleScroll, this);
        //Events.bind('load').to(handleScroll, this);
        //Events.bind('resize').to(handleScroll, this);
      }
      return this;
    }
  };
}();
