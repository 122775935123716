App.Modules = App.Modules || {};
App.Modules.TeamMemberSlideshow = function () {

  var createSlider = function(selector)
  {
    if( $(selector).length ) {

      $(selector).each( function() {
        $(this).bxSlider({
          controls: true,
          pager: false,
          minSlides: 1,
          maxSlides: 4,
          moveSlides: 1,
          shrinkItems: true,
          nextSelector: $(this).parent().find('.team-member-pager .right'),
          nextText: '&#xf054;',
          prevSelector: $(this).parent().find('.team-member-pager .left'),
          prevText: '&#xf053;',
          slideWidth: 296,
          slideMargin: 25,
          touchEnabled: false,
          oneToOneTouch: false,
          preventDefaultSwipeY: false,
          onSlideNext: function ($slideElement, oldIndex, newIndex) {
            Events.publish('slide');
          },
          onSlidePrev: function ($slideElement, oldIndex, newIndex) {
            Events.publish('slide');
          }
        });
      });

    }
  };

  return {

    init: function() {
      createSlider('.team-member-slideshow-holder .bxslider');
      return this;
    },
    events: function() {
      return this;
    }
  };
}();

