App.Modules = App.Modules || {};
App.Modules.HomepageStatsTicker = function () {

  var o = {
    hasRun: false,
    statsArr: []
  };

  var check = function() {
    if( $(document).scrollTop() >= ($('.stats').outerHeight(true) - 140) && ! o.hasRun && $('.home').length && $(window).width() >= 680 )
    {
        Events.publish("ad/animate", {});
        o.hasRun = true;
    }
  };

  var buildStatsArr = function()
  {
    if( $(window).width() >= 680 ) {
      $('.stat').each(function () {
        var $this = $(this);

        var orig = $this.text();
        o.statsArr.push($this.text());
        $this.html(  Math.ceil( parseInt( $this.text().replace(/\D/g, ''), 10) / 3 ) );
        $this.html( $this.html().replace(/\B(?=(\d{3})+\b)/g, ',') );
        if( orig.indexOf('$') !== -1 )
        {
          $this.html( '$' + $this.html() + 'B');
        }
        $this.prop('number', $this.text().replace(/\D/g, '') );
      });
    }
  };

  var animate = function() {
    if(o.statsArr.length) {
      $('.stat').each(function (index) {
        var origVal = o.statsArr[index];
        $(this).delay(index * 150).animateNumber({
            number: origVal.replace(/\D/g, ''),
            numberStep: ( origVal.indexOf('$') == -1 ? $.animateNumber.numberStepFactories.separator(',') : function(now, tween) {
              var floored_number = Math.floor(now),
                target = $(tween.elem);

              target.prop('number', now).text('$' + floored_number + 'B');
            } )
          },
          1000,
          function () {
            $(this).html(origVal);
          });
      });
    }
  };

  return {
    init: function() {
      buildStatsArr();
      return this;
    },
    events: function() {
      Events.bind('scroll').to(check, this);
      Events.subscribe("ad/animate", animate, this);
      return this;
    }
  };
}();

