App.Modules = App.Modules || {};
App.Modules.SupplierCampaignPagaination = function () {

  var page = 0,
    perPage = 5,
    $container = $('.js-map-list__supplier-campaign'),
    $items = $container.find('.map-row'),
    numberPages = Math.floor($items.length / perPage)
    $prevPage = $('.supplier-campaign-page-left'),
    $nextPage = $('.supplier-campaign-page-right'),
    $pageCount = $('.supplier-campaign-page-count');

  var addPagination = function (data) {
    var changePage = function (pageNumber) {
        var lp, indexTimePage = page * perPage;
        for (lp = 0; lp < $items.length; lp++) {
          if (lp >= indexTimePage && lp < indexTimePage + perPage) {
            $($items[lp]).removeClass('pagination-hidden');
          } else {
            $($items[lp]).addClass('pagination-hidden');
          }
        }
      },
      
      attachPaginationEvents = function () {
        $prevPage.on('click', prevPage);
        $nextPage.on('click', nextPage);
      },
      
      prevPage = function () {
        if (page) {
          changePage(--page);
          changePageCount(page);
        }
      },
      
      nextPage = function () {
        if (page < numberPages) {
          changePage(++page);
          changePageCount(page);
        }
      },
      
      changePageCount = function (targetPage) {
        if ($items.length) {
          $pageCount.html('' + (targetPage + 1) + '/' + numberPages);
        } else {
          $pageCount.parent().hide();
        }
      };

    if ($items.length) {
      changePage(0);
    }

    attachPaginationEvents();
    changePageCount(0);
  };

  addPagination();

  return {
    init: function() {
      return this;
    },
    events: function() {
      //Events.bind('DOMContentLoaded', 'document').to(addPagination, this);
      return this;
    }
  };
}();

