App.Modules = App.Modules || {};
App.Modules.SupplierCampaignFacebookPixel = function () {

  var facebookEvent = function (data) {
    fbq('track', 'Lead');
  },
  
  bindFacebookEvents = function () {
    // Directions
    $(document).on(
        'click',
        '.list__supplier-campaign__details .supplier-campaign-address-detail-directions',
        function () {
            facebookEvent();
        }
    );

    // Phone
    $(document).on(
        'click',
        '.list__supplier-campaign__details .company-link__phone a',
        function () {
            facebookEvent();
        }
    );

    // Web site
    $(document).on(
        'click',
        '.list__supplier-campaign__details .company-link__website a',
        function () {
            facebookEvent();
        }
    );
  };

  bindFacebookEvents();

  return {
    init: function() {
      return this;
    },
    events: function() {
        // // Directions
        // Events.bind('click', '.list__supplier-campaign__details .supplier-campaign-address-detail-directions').to(facebookEvent, this);

        // // Phone
        // Events.bind('click', '.list__supplier-campaign__details .company-link__phone a').to(facebookEvent, this);

        // // Web site
        // Events.bind('click', '.list__supplier-campaign__details .company-link__website a').to(facebookEvent, this);
      return this;
    }
  };
}();