App.Modules = App.Modules || {};
App.Modules.AdvertBanner = function () {

  var menuAnimating = false;

  var $banner = $('.advert-banner'),
    $content = $('.content'),
    bannerHeight = $banner.outerHeight(true);

  var touchPos = 0;
  var touchDelta = 0;

  var touchStart = function(data) {
    touchPos = data.event.touches[0].pageY;
    console.log('a');
  };

  var touchMove = function(data){
    console.log('b');
    touchDelta = touchPos - data.event.touches[0].pageY;

    if( touchDelta !== 0 )
    {
      data.event.deltaY = touchDelta;
      shrinkMenu(data);
    }
  };

  var shrinkMenu = //App.Helpers.debounce(
    function(data)
    {
      if( ! $('body').hasClass('no-scroll') ) {
        if (parseInt(data.event.deltaY, 10) !== 0) {
          if (data.event.deltaY > 0) {
            if (!menuAnimating) {
              menuAnimating = true;
              //$content.animate({marginTop: '-=' + bannerHeight});
              $banner.slideUp(400, function () {
                menuAnimating = false;
              });
            }
          } else {
            if (!menuAnimating) {
              menuAnimating = true;
              //$content.animate({marginTop: '+=' + bannerHeight});
              $banner.slideDown(400, function () {
                menuAnimating = false;
              });
            }
          }
        }
      }
    };//, 50);

  return {
    init: function() {

      if( $banner.length )
      {
        var t = setTimeout(function() {
          $banner.slideToggle();
          $content.animate({marginTop: '+=' + bannerHeight});
        }, 1500);
      }
      return this;
    },
    events: function() {
      Events.bind(
        "onwheel" in document.createElement("div") ? "wheel" : // Modern browsers support "wheel"
          document.onmousewheel !== undefined ? "mousewheel" : // Webkit and IE support at least "mousewheel"
            "DOMMouseScroll"
      ).to(shrinkMenu, this, {}, false);
      Events.bind("touchstart").to(touchStart, this, {}, false);
      Events.bind("touchmove").to(touchMove, this, {}, false);
      return this;
    }
  };
}();
