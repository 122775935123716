App.Modules = App.Modules || {};
App.Modules.FormValidate = function () {
  var o = { };

  return {
    init: function() {
      $('.form.validate').find('form').validate({
        focusInvalid: false,
        invalidHandler: function(event, validator) {
          var $el = $(this).find(':invalid').first();
          $el.focus();

          $('body, html').scrollTop( $el.offset().top - $('header').outerHeight(true) - 55 );
        }
      });
      return this;
    },
    events: function() {
      return this;
    }
  };
}();

