App.Modules = App.Modules || {};
App.Modules.Timeline = function () {

  var $items = $('.timeline-item, #history-chart-svg');

  function isElementInViewport(el) {
    var rect = el.getBoundingClientRect();
    return (
      rect.top >= 0 &&
      //rect.left >= 0 &&
      rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) //&&
      //rect.right <= (window.innerWidth || document.documentElement.clientWidth)
    );
  }

  function callbackFunc() {
    $items.each(function() {
      if (isElementInViewport($(this)[0])) {
       $(this).addClass("in-view");
      }
    });
  }

  return {
    init: function() {
      callbackFunc();
      return this;
    },
    events: function() {
      Events.bind('load').to(callbackFunc, this);
      Events.bind('resize').to(callbackFunc, this);
      Events.bind('scroll').to(callbackFunc, this);
      return this;
    }
  };
}();

