App.Modules = App.Modules || {};
App.Modules.JoinAdCanadaNotice = function () {

  var $countrySelect = $('#country'),
    $types = $('input[name="type"]');

  var checkNotice = function(data)
  {
    if( $countrySelect.length && $types.length ) {
      if ($countrySelect.val() == 'Canada' && $types.filter(':checked').val() == 'Supplier') {
        //display canada electrical supplier notice

        $('body').toggleClass('no-scroll');
        $('.shade').html( Handlebars.templates.join_ad_canada_notice(
          {
            french: window.location.href.substr(-2) == 'fr',
            spanish: window.location.href.substr(-2) == 'es',
            english: window.location.href.substr(-2) == 'en' ||  window.location.href.substr(-2) == 'ad'
          }
        ) ).fadeIn();

      }
    }
  };

  return {
    init: function() {
      return this;
    },
    events: function() {
      if( document.getElementById('country') ) {
        Events.bind('change', '#country').to(checkNotice, this);
        Events.bind('focus', 'input[name="type"]').to(checkNotice, this);
      }
      return this;
    }
  };
}();
