App.Modules = App.Modules || {};
App.Modules.Introduction = function () {
   var o = { };

   return {
      init: function() { return this; },
      events: function() {
         return this;
      }
   };
}();

