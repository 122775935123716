"use strict";

var $ = window.jQuery || function(selector)
{
  var result = document.querySelectorAll(selector);
  if( ! result ) { return undefined; }
  if( result.length == 1) { return result[0]; }
  return result;
};

if (!String.prototype.startsWith) {
  String.prototype.startsWith = function(searchString, position){
    position = position || 0;
    return this.substr(position, searchString.length) === searchString;
  };
}

(function() {
   var App = {};
   window.App = App;

   document.addEventListener('DOMContentLoaded', function(e) {
     for (var module in App.Modules) {
        App.Modules[module].init().events();
     }
   });
})();
