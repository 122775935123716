App.Modules = App.Modules || {};
App.Modules.QuoteBox = function () {
  var imgSlider, txtSlider;

  return {

    init: function() {
      var $imgSelector = $('.img-frame .bxslider'),
        $txtSelector = $('.quote-text .bxslider'),
        currentSlideIndex;


      var t = setTimeout( function()
      {
        if( $imgSelector.length ) {
          imgSlider = $imgSelector.bxSlider({
            controls: false,
            pager: false,
            minSlides: 1,
            maxSlides: 1,
            moveSlides: 1,
            slideWidth: "300",
            shrinkItems: true,
            // nextSelector: ".pager .right",
            nextText: '&#xf054;',
            //prevSelector: ".pager .left",
            prevText: '&#xf053;',
            touchEnabled: false,
            oneToOneTouch: false,
            preventDefaultSwipeY: false
          });
        }


        if( $txtSelector.length && $txtSelector.children('li').length > 1 ) {
          txtSlider = $txtSelector.bxSlider({
            controls: true,
            pager: false,
            minSlides: 1,
            maxSlides: 1,
            moveSlides: 1,
            //slideWidth: "350",
            shrinkItems: true,
            nextSelector: ".quote-pager .right",
            nextText: '&#xf054;',
            prevSelector: ".quote-pager .left",
            prevText: '&#xf053;',
            touchEnabled: false,
            oneToOneTouch: false,
            preventDefaultSwipeY: false,
            onSlideNext: function ($slideElement, oldIndex, newIndex) {
              currentSlideIndex = newIndex;
              imgSlider.goToNextSlide();
              $(this).parent().parent().parent().next().find('.page-nums').text( (newIndex + 1) + ' / ' + this.getSlideCount() );
            },
            onSlidePrev: function ($slideElement, oldIndex, newIndex) {
              currentSlideIndex = newIndex;
              imgSlider.goToPrevSlide();
              $(this).parent().parent().parent().next().find('.page-nums').text( (newIndex + 1) + ' / ' + this.getSlideCount() );
            }
          });
        } else {
          $('.quote-box').find('.pager').hide();
        }

        $(window).on('resize', function() {
          if( txtSlider ) {
            txtSlider.reloadSlider(
              {
                controls: true,
                pager: false,
                minSlides: 1,
                maxSlides: 1,
                moveSlides: 1,
                startSlide: currentSlideIndex,
                //slideWidth: "350",
                shrinkItems: true,
                nextSelector: ".quote-pager .right",
                nextText: '&#xf054;',
                prevSelector: ".quote-pager .left",
                prevText: '&#xf053;',
                touchEnabled: false,
                oneToOneTouch: false,
                preventDefaultSwipeY: false,
                onSlideNext: function ($slideElement, oldIndex, newIndex) {
                  currentSlideIndex = newIndex;
                  imgSlider.goToNextSlide();
                  $(this).parent().parent().parent().next().find('.page-nums').text( (newIndex + 1) + ' / ' + this.getSlideCount() );
                },
                onSlidePrev: function ($slideElement, oldIndex, newIndex) {
                  currentSlideIndex = newIndex;
                  imgSlider.goToPrevSlide();
                  $(this).parent().parent().parent().next().find('.page-nums').text( (newIndex + 1) + ' / ' + this.getSlideCount() );
                }
              }
            );
          }
          if( imgSlider ) {
            imgSlider.reloadSlider(
              {
                controls: false,
                pager: false,
                minSlides: 1,
                maxSlides: 1,
                moveSlides: 1,
                startSlide: currentSlideIndex,
                slideWidth: "300",
                shrinkItems: true,
                // nextSelector: ".pager .right",
                nextText: '&#xf054;',
                //prevSelector: ".pager .left",
                prevText: '&#xf053;',
                touchEnabled: false,
                oneToOneTouch: false,
                preventDefaultSwipeY: false
              }
            );
          }
        });
      }, 100);

      return this;
    },
    events: function() {
      return this;
    }
  };
}();

