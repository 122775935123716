App.Modules = App.Modules || {};
App.Modules.FullScreenVideo = function () {

  var openVideo = function(data) {

    $('body').toggleClass('no-scroll');

    var iFrameVideo =
      '<iframe' +
      ' src="//www.youtube.com/embed/' + $(data.eventElement).attr( 'data-video' ) +
      '?rel=0' +
      '&autoplay=1' +
      '&showinfo=0' +
      '&modestbranding=1' +
      '&theme=light' +
      ' frameborder="0"' +
      ' allowfullscreen>' +
      '</iframe>';

    $('.video-embed-container').html(iFrameVideo);
    $('.video-shade').fadeIn();
  };

  var closeVideo = function() {
    $('.video-shade').fadeOut(400, function() {
      $('.video-embed-container').html('');
      $('body').toggleClass('no-scroll');
    });
  };

  return {
    init: function() {
      return this;
    },
    events: function() {
      Events.bind('click', '[data-video]').to(openVideo, this);
      Events.bind('click', '.video-shade .fa-close').to(closeVideo, this);
      return this;
    }
  };
}();

