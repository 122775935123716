App.Modules = App.Modules || {};
App.Modules.UsMap = function () {

  return {
    init: function() {
      var $mapDiv = $('#vmap'),
          $mapDestination = $mapDiv.data('url');

      if( $mapDiv.length ) {
        $mapDiv.vectorMap(
          {
            map: 'usa_en',
            backgroundColor: 'none',
            borderColor: '#E5E5E5',
            hoverColor: '#6FA287',
            selectedColor: 'rgb(116, 176, 74)',
            color: '#44693D',
            showLabels: false,
            showTooltip: true,
            enableZoom: false,
            onRegionClick: function(e, code)
            {
               if ($mapDestination)
               {
                   window.location.href = $mapDestination + code;
               }
            }
          }
        );
      }
      return this;
    },
    events: function() {
      return this;
    }
  };
}();

