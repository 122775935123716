App.Modules = App.Modules || {};
App.Modules.AjaxModal = function () {

  var $shade = $('.shade');

  var open = function(data) {
    $shade.fadeIn();
    $('body').addClass('no-scroll');
    var params =  $(data.eventElement).data();

    $.get(
      params['modalUrl'],
      {key: params['modalKey']},
      function(data) {
        $shade.html(data);
      }
    )
  };

  var close = function(data) {
    var $clickTarget = $(data.eventTarget);
    if( $clickTarget.hasClass('shade') ||  $clickTarget.hasClass('close') || $clickTarget.hasClass('.fa-close') ) {
      $('body').removeClass('no-scroll');
      $shade.fadeOut(400, function () {
        $shade.html('');
      });
    }
  };

  return {
    init: function() {
      return this;
    },
    events: function() {
      Events.subscribe('slide',
        function(){
          Events.bind('click', '[data-modal]').to(open, this);
          Events.bind('click', '.shade, .shade .fa-close').to(close, this, {}, false);
        }, this);
      Events.bind('click', '[data-modal]').to(open, this);
      Events.bind('click', '.shade, .shade .fa-close').to(close, this, {}, false);
      return this;
    }
  };
}();

